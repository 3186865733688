import { reactive, ref } from 'vue';
import Prefer from '@/api/Prefer';
import { useRouter } from 'vue-router';
import MessageBox from '@/components/messageBox/Index';

export default () => {
  const REFS = {
    categories: {
      title: '歡迎加入 JODY<br>你想要準備的考試是哪一類呢?',
      multi: true,
      icon: true,
      prop: 'c_keys',
      key: 'c_key',
      name: 'c_name',
    },

    titles: {
      title: '有興趣的考試單位是？（可複選）',
      multi: true,
      prop: 't_keys',
      key: 't_key',
      name: 't_name',
    },
    subjects: {
      title: '以下哪些科目是你有興趣的呢？ （可複選） ',
      multi: true,
      prop: 'sbj_keys',
      key: 'sbj_key',
      name: 'sbj_name',
    },
    times: {
      title: '您準備考試的時間大約是？',
      multi: false,
      prop: 'time',
      key: 'time_id',
      name: 'name',
    },
  };
  const steps = Object.keys(REFS);
  const active = ref(0);
  const rows = reactive([]);

  const fetcher = () => {
    Prefer.list().then((res) => {
      if (res.code === 1) {
        const data = [];

        Object.keys(res.data).forEach((key) => {
          const opts = res.data[key].map((x) => {
            return {
              ...x,
              selected: false,
            };
          });

          const idx = steps.findIndex((x) => x === key);
          data[idx] = {
            id: key,
            prop: REFS[key].prop,
            multi: REFS[key].multi,
            title: REFS[key].title,
            opts,
          };
        });

        rows.splice(0, 0, ...data);
        console.log(rows);
      }
    });
  };

  const getFrom = () => {
    const form = {};
    rows.forEach((row) => {
      if (row.multi) {
        if (!form[row.prop]) {
          form[row.prop] = [];
        }
        row.opts.forEach((x) => {
          if (x.selected) {
            form[row.prop].push(x.id);
          }
        });
      } else {
        const opt = row.opts.find((x) => x.selected);

        if (opt) {
          form[row.prop] = opt.id;
        }
      }
    });
    return form;
  };
  const router = useRouter();
  const record = () => {
    return Prefer.record({ ...getFrom() }).then((res) => {
      if (res.code === 1) {
        console.log(res);
      }
      return res;
    });
  };

  const select = (opt) => {
    if (!rows[active.value].multi) {
      rows[active.value].opts.forEach((x) => {
        x.selected = false;
      });
    }
    opt.selected = !opt.selected;
  };
  const submit = async () => {
    await record();

    await MessageBox.confirm(
      '喜好設定送出?',
      '之後會推薦相關的內容給您',
      'success',
    );

    router.push({ name: 'Assistant' });
  };
  const skip = async () => {
    await MessageBox.confirm('確定跳過?');
    await record();
    router.push({ name: 'Assistant' });
  };
  const next = () => {
    if (active.value >= steps.length - 1) return;
    active.value++;
  };
  const prev = () => {
    if (active.value === 0) return;
    active.value--;
  };
  fetcher();

  return { rows, next, prev, active, select, skip, submit };
};

<template>
  <div
    v-if="rows[active]"
    class="flex flex-col items-center min-h-[calc(100vh-64px)] pt-12"
  >
    <h2 class="text-center text-2xl" v-html="rows[active].title"></h2>
    <div class="flex flex-wrap max-w-4xl mt-10 md:justify-center">
      <a
        v-for="opt in rows[active].opts"
        :key="opt.key"
        href="#"
        class="btn-white mb-2"
        :class="opt.selected ? 'active' : ''"
        @click.prevent="select(opt)"
      >
        <i v-if="opt.icon">icon</i>
        {{ opt.name }}
      </a>
    </div>
    <img
      src="@/assets/imgs/index-role.png"
      alt
      srcset
      class="w-[360px] mt-8 mb-12"
    />
    <div
      class="w-full shadow bg-white py-4 flex justify-center items-center mt-auto sticky bottom-0"
    >
      <a href="#" class="underline text-sm text-gray mr-6" @click.prevent="skip"
        >略過</a
      >
      <a v-show="active !== 0" href="#" class="btn-white" @click.prevent="prev"
        >上一步</a
      >
      <a
        v-show="active !== rows.length - 1"
        href="#"
        class="btn-primary"
        @click.prevent="next"
        >下一步</a
      >
      <a
        v-show="active === rows.length - 1"
        href="#"
        class="btn-primary"
        @click.prevent="submit"
        >完成</a
      >
    </div>
  </div>
</template>
<script>
import usePrefer from './usePrefer';

export default {
  setup(props) {
    const { active, rows, next, prev, skip, submit, select } = usePrefer();

    return { active, rows, next, skip, prev, select, submit };
  },
};
</script>
<style lang="scss" scoped></style>

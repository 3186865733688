<template>
  <div><Prefer /></div>
</template>
<script>
import Prefer from '@/modules/prefer/Index.vue';
export default {
  components: {
    Prefer,
  },
  setup(props) {},
};
</script>
<style lang="scss" scoped></style>
